<template>
    <div class="page-tv-devices-kaltura container">
        <div class="row">
            <div class="col-12">
                <app-search-box
                    label="Household ID"

                    :loading="loading"

                    @search="onSearch"
                />
            </div>
        </div>
    </div>
</template>

<script>
import appSearchBox from '@/components/app-search-box'

export default {
    components: {
        appSearchBox,
    },

    data() {
        return {
            loading: false,

            filter: {
                sort: {
                    field: 'Name',
                    order: 'asc'
                },
            },

            pagination: {
                page: 1,
                limit: 10,
            },
        }
    },

    methods: {
        onSearch(value) {
            this.loading = true

            const word = value.trim()

            const params = {
                'SearchOptions.PageNumber': this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,

                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,

                'SmartSearch': word,
            }

            this.$store.dispatch('getTVDevicesSearchResults', { params })
                .then(({ PageInfo, SearchResults }) => {
                    if (SearchResults.length) {
                        this.$store.commit('saveTVDevicesSearchResults', { PageInfo, SearchResults })

                        this.$router.push({
                            name: 'devices-kaltura-search-results',
                            query: {
                                search: word,
                            }
                        })
                    }

                    this.loading = false
                })
                .catch(error => {
                    console.log(error)

                    this.loading = false
                })
        }
    },
}
</script>

<style lang="scss">
.page-tv-devices-kaltura {
    padding-bottom: 80px;
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {}
</style>